/* Global Styles */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

.custom-navbar {
  background-color: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 80px;
}

.custom-logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-left: 10px;
}

.logo-container {
  margin-right: 20px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 30px;
  margin: 0;
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
}

nav ul li a {
  color: #000;
  text-decoration: none;
  font-size: 15px;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

nav ul li a::after {
  content: '';
  width: 0;
  height: 2px;
  background: #000;
  position: absolute;
  left: 0;
  bottom: -6px;
  transition: 0.5s;
}

nav ul li a:hover::after {
  width: 100%;
}

.navbar-toggler {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

section {
  padding: 60px 15px;
}

/* Hero Section */
.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  text-align: left;
  padding: 60px 15px;
  height: 100vh;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 45%;
  text-align: left;
}

.main-title {
  font-family: 'Poppins', sans-serif;
  font-size: 72px;
  font-weight: 700;
  margin: 0;
}

.subtitle {
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-weight: 400;
  color: #555;
  margin-top: 10px;
}

.hero-memoji {
  position: relative;
  width: 350px;
  margin-left: 60px;
  pointer-events: none;
  z-index: 2;
}

/* About Section */
.about-section {
  padding: 110px 15px;
  background-color: #e6f0ff;
  color: #003366;
}

.about-layout {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  gap: 20px;
}

.about-image {
  flex: 2; 
  width: 100%; 
  height: auto;
}

.about-text {
  flex: 3; 
  text-align: left;
}

.about-text h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #333;
}

/* About Cards */
.about-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.about-card h3 {
  font-size: 20px;
  font-weight: bold;
  color: #003366;
  margin-bottom: 15px;
}

.about-card ul {
  list-style: none;
  padding: 0;
}

.about-card ul li {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
}

.about-card p {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.icon {
  font-size: 36px;
  color: #003366;
  margin-bottom: 15px;
}

.row .col-md-4 {
  display: flex;
  flex-direction: column;
}

/* Contact Section */
.contact-section {
  padding: 60px 15px;
  background-color: #f9f9f9;
}

.contact-header h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #003366;
}

.contact-header p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

.contact-form .form-control {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 1rem;
}

.contact-form button {
  background-color: #5689db;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  transition: all 0.3s ease;
  margin-top: 15px;
}

.contact-form button:hover {
  background-color: #003366;
  color: #fff;
}

/* Footer */
.custom-footer {
  background-color: #003366;
  color: #ffffff;
  padding: 50px 15px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer-logo-img {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.footer-quote p {
  font-size: 1rem;
  font-style: italic;
  color: #ffffff;
  margin: 0;
}

.footer-icons {
  display: flex;
  gap: 15px;
}

.footer-icons a {
  color: #ffffff;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.footer-icons a:hover {
  color: #5689db;
}

.footer-copyright p {
  font-size: 0.9rem;
  margin: 0;
  color: #cccccc;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 85vh; 
    padding: 0 5px; 
    margin-top: 100px;
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px; 
  }

  .main-title {
    font-size: 45px; 
    font-weight: 700;
    white-space: nowrap; 
  }

  .subtitle {
    font-size: 23px;
    font-weight: 400;
    color: #555;
    white-space: nowrap; 
  }

  .hero-memoji {
    width: 400px; 
    margin-top: -135px;
  }

  .about-card {
    margin-bottom: 20px; 
  }

  .about-layout {
    flex-direction: column; 
    align-items: center;
  }

  .about-image {
    width: 90%; 
  }

  .about-text {
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 1023px) {
  .nav-links li a {
    text-decoration: none !important;
  }

  .nav-links li a::after {
    display: none !important;
    width: 0 !important;
  }
  .custom-navbar {
    height: 100px; 
    padding: 20px 15px; 
    display: flex;
    align-items: center;
  }

  .navbar-toggler {
    display: block !important; 
  }

  .navbar-collapse {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 999;

    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
  }

  .navbar-collapse.show {
    max-height: 300px; 
  }

  .navbar-collapse.hide {
    max-height: 0;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .nav-links li {
    width: 100%;
    padding: 15px 0;
    text-align: center;
    background: #f5f5f5; 
    transition: background 0.3s;
  }

  .nav-links li:not(:last-child) {
    border-bottom: none; 
  }

  .nav-links li a {
    display: block;
    width: 100%;
    text-decoration: none !important; 
    color: black;
  }
}







